@import "../../../shared/styles/index.scss";

.Pagination {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 18px;

  .Pagination-direction {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.24;
    color: white;
    padding: 0 5px;
    transition: all 250ms ease-in-out;
  }
  .Pagination-direction--right {
    text-align: right;
  }
  .Pagination-pages {
    display: inline-block;
    .Pagination-page {
      font-family: Righteous;
      font-size: 15px;
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      line-height: 20px;
      cursor: pointer;
      color: black;
      opacity: 0.35;
      text-align: center;
      &.is-active {
        background-color: white;
        color: #39519e;
        opacity: 1;
      }
    }
  }
}
