@import "../../shared/styles/index.scss";



.Competition-cover {
	box-sizing: border-box;
	padding: 60px 110px;
	max-width: 1440px;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;

	@include xLarge-desktop {
		padding: 60px 105px;
	}
	

	.Competition-card {
		margin: 0 30px 0 40px;
		max-width: 400px;

		@include xLarge-desktop {
			margin: 0 40px 0 80px;
		}
	}
}