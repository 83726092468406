@import '../../shared/styles/index.scss';

.UploadedFiles {
    margin-bottom: 20px;
    padding-top: 20px;
    font-weight: bold;

    .UploadedFile {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(black, 0.2);
        color: $color-white;

        img {
            cursor: pointer;
        }
    }
}
