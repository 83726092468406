@import "../../shared/styles/index.scss";

/**
DEFINE HERE SHARED STYLES FOR INPUTTEMPLATE
*/

.Input {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  margin-bottom: 1.125rem;
  @include font-source-sans-pro(18, $color-white, 400);

  & input {
    height: 50px;
    padding: 0 25px;
    border-radius: 5px;
    border: none;
    background-color: $color-darkViolet;
    outline: none;
    box-shadow: none;
    color: $color-white;
    max-width: 50%;
    font-size: 0.875rem;
    font-weight: lighter;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba($color-white, 0.21);
      font-size: 0.875rem;
      font-weight: lighter;
    }

    &[type="checkbox"] {
      -webkit-appearance: none;
      width: 35px;
      max-width: 35px;
      height: 35px;
      max-height: 35px;
      border: 1px solid rgba($color-white, 0.21);
      padding: 0;
      flex-grow: 0;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      background-position: center;
      background-size: 0;
      background-repeat: no-repeat;
      cursor: pointer;

      transition: all 50ms ease-out;

      &:checked {
        background-size: 90%;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAABZklEQVRoQ+2aMU7DQBBF/5A2RY6w9BhxgyQ3gJtAj8FgergJ3CDODRCmZ4+QghYGLVIUiBzISJFmY32X1mhn/vw33Rf0/JOe6wMF7rvDdLB8CWMRnAAYZebmQhXP9XGc/zXXRgertzD6eMejQCaZCfs1jkKbwRBn1WFcdM25UWDZhlnu4paCksi6iNOtBV62YXIAmeXs3Ppsn9DpXRGb9f+dDpav4VxU7vdJoIpe1EfxYSuBV22oALneJ4GA3twWsaLAtAE6mCW7RHRlCxEloh4b4A3yBj24M/QkokTUgItHKREloh7cGXoSUSJqwMWjlIgSUQ/uDD2JKBE14OJRSkSJqAd3hp5ElIgacPEoJaJE1IM7Q08iulpW70MIvY+RJC/LNjQCGRuOwK1UofO6iJ2JrP+iXE+5i0ziBkOcmqNcSzsSrimMJ5pXGE8F32G8rnTTT5QYp3Q7rB01poM7WqTbM7138Ase9ipI7Sk4dwAAAABJRU5ErkJggg==');
      }
    }
  }

  & label {
    font-size: 22px;
    font-weight: 300;
    text-align: left;

    & span {
      text-align: left;
    }
  }

  &.Input--vertical {
    flex-direction: column;

    & label {
      display: inline-flex;
      flex-grow: 1;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;

      & span {
        width: 100%;
      }
    }

    & input {
      display: inline-flex;
      flex-grow: 1;
      width: calc(100% - 32px);
      max-width: 100%;
    }

    &.Input--reverse {
      flex-direction: column-reverse;
      margin-bottom: 0;
      margin-top: 1rem;

      & label {
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
  }

  &.Input--start {
    justify-items: flex-start;
    align-items: flex-start;

    &.Input--reverse {
      justify-items: flex-start;
      justify-content: flex-start;
    }

    & label {
      text-align: left;

      & span {
        text-align: left;
      }
    }

    & input,
    & button {
      text-align: right;
    }
  }

  &.Input--shrink {
    flex-shrink: 1;
    width: auto;
  }

  &.Input--end {
    align-content: flex-end;
    align-items: center;

    &.Input--reverse {
      justify-items: flex-end;
      justify-content: flex-end;
    }

    & label {
      text-align: right;

      & span {
        text-align: right;
      }
    }

    & input,
    & button {
      text-align: right;
    }
  }

  &:not(.Input--vertical) {
    & label {
      display: inline-flex;
      flex-shrink: 1;
    }

    & input {
      display: inline-flex;
      flex-grow: 1;
    }

    &.Input--reverse {
      flex-direction: row-reverse;

      & label {
        display: inline-flex;
        flex-shrink: 1;
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  & .Input--small--label {
    padding-left: 15px;
    font-size: 18px;
    opacity: 0.6;
  }

  & a {
    margin: 16px 0;
  }

  &.is-disabled {

    & input,
    & button,
    & div:first-of-type {
      opacity: .8;
      cursor: not-allowed !important;
      pointer-events: none;
      touch-action: none;
    }

    & label {
      opacity: .5;
      cursor: not-allowed;
    }

  }

  &.is-required {
    & label {
      &::after {
        content: '*';
        color: $color-red;
        position: relative;
        top: 0;
        margin: 0 5px;
      }
    }
  }
}

.AutoCompleteTextInput {
  position: relative;
  bottom: -5.9rem;
  z-index: 9999;
  width: 100%;
  max-width: 80%;
  background-color: transparent;
  margin-bottom: 0;

  & input {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    margin-bottom: .2rem;
  }

  &.is-disabled {
    cursor: not-allowed;
    width: 100%;
    max-width: 100%;
    opacity: 1;
  }
}

.Input-label {
  @include font-source-sans-pro(18, $color-white, 400);
  display: inline-flex;
  flex-grow: 1;

  & span {
    display: inline-block;
    overflow: hidden;
  }
}

.is-dark {
  & label {
    color: rgba(0, 0, 0, 0.31);
  }

  & input {
    background-color: rgba(1, 1, 1, 0.21);

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-white;
    }
  }
}
