@import "../../shared/styles/index.scss";

.Status {
  display: flex;
  align-items: center;

  .Status-ball {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background-color: $color-red;
  }

  .Status-ball-green {
    background-color: $color-green;
  }
}
