/* Fonts */
$font-title: "Righteous",
Helvetica,
Arial,
sans-serif;
$font-base: "Source Sans Pro",
Helvetica,
Arial,
sans-serif;

@mixin font-righteous($size: false,
$colour: false,
$weight: false,
$lh: false,
$ls: false
) {
  font-family: $font-title;

  @if $size {
    font-size: $size + px;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh + px;
  }

  @if $ls {
    letter-spacing: $ls + px;
  }
}

@mixin font-source-sans-pro($size: false,
$colour: false,
$weight: false,
$lh: false,
$ls: false
) {
  font-family: $font-base;

  @if $size {
    font-size: $size + px;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh + px;
  }

  @if $ls {
    letter-spacing: $ls + px;
  }
}
