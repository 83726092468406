@import "../../shared/styles/index.scss";

/*
Maybe component does not support active class so with css modules is necessary to use !important
@todo: Waiting for updating
 */

.rdw-option-active {
	background-color: #FFF !important;
	border-color: #FFF !important;;
}

.public-DraftStyleDefault-block {
	margin: 0;
}
