@import "../../shared/styles/index.scss";

.SwitchFilter {
  position: relative;
  display: flex;
  align-items: center;

  .SwitchFilter-label {
    display: inline-block;
    @include font-source-sans-pro(22, $color-white, 400);
    margin: 0 20px 0 0;
  }

  .SwitchFilter-content {
    width: 290px;
    height: 36px;
    border-radius: 17px;
    @include background-opacity(rgb(1, 22, 48), 0.15);
    box-sizing: border-box;
    border: 1px solid $color-white;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .SwitchFilter-option {
    background-color: transparent;
    border: 0;
    height: 100%;
    text-transform: uppercase;
    @include font-source-sans-pro(14, $color-white, 400);
    border-radius: 17px;
    white-space: nowrap;
    text-align: center;
    min-width: 50%;
    cursor: pointer;
    transition: background-color 250ms;

    &.SwitchFilter-option--selected {
      background-color: $color-orange;
      cursor: default;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
