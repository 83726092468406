.CompetitionLeaderboardButtons {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  div {
    border-top: 2px solid #00000000;
    cursor: pointer;
    opacity: 0.13;
    flex-grow: 1;
    flex-basis: 100px;
    text-align: center;
    padding: 12px 0;
    text-transform: uppercase;
    &.isActive {
      border-top: 2px solid #00000038;
      opacity: 1;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
