@import "../../shared/styles/index.scss";

.ShortLeaderboard {
	width: 100%;
	max-width: 220px;
	border-radius: 5px;
	box-sizing: border-box;
	@include background-opacity($color-white, 0.1);
	padding: 10px 20px 0;

	.ShortLeaderboard-header {
		display: flex;
	}

	.ShortLeaderboard-icon {
		margin: 0 10px 0 0;
	}

	.ShortLeaderboard-title {
		@include font-righteous(16, $color-white, 400);
		margin: 12px 0 0;
	}

	.ShortLeaderboard-temp {
		@include font-righteous(12, false, 400);
		@include color-opacity($color-white, 0.5)
	}

	.ShortLeaderboard-list {
		list-style-type: decimal;
		list-style-position: inside;
		padding: 0;
	}

	.ShortLeaderboard-item {
		padding: 10px 0 0 2px;
		@include font-source-sans-pro(12, $color-white, 400);

		&:first-child {
			padding: 10px 0;
			@include font-source-sans-pro(18, $color-pink, 400);
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}

		&:nth-child(2),
		&:nth-child(3) {
			padding: 10px 0 10px 1px;
			@include font-source-sans-pro(14, $color-white, 400);
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}


		> span {
			position: relative;
			left: 10px;
		}
	}

	.ShortLeaderboard-button {
		@include font-source-sans-pro(12, $color-white, 700);
		padding: 7px 16px;
		cursor: pointer;
		border-radius: 51px;
		display: inline-block;
		text-decoration: none;
		text-align: center;
		margin: 20px 0;
		text-transform: uppercase;
		box-sizing: border-box;
		background-color: transparent;
		border: 1px solid $color-white;
		width: 100%;
		transition: all 300ms;

		&:hover {
			background-color: $color-white;
			color: $color-violet;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

}
