@import "../../shared/styles/index.scss";

.WelcomeIllustration {
	position: relative;
	width: 75vh;

	.WelcomeIllustration-overlay {
		width: 100%;
		z-index: 9999;
		backface-visibility: hidden;
		perspective: 300px;
		transition: transform 1s ease-out;
		transform-style: preserve-3d;
		transform-origin: 0 0 0;
	}

	.WelcomeIllustration-far {
		width: 35vh;
		z-index: 1;
		position: absolute;
		left: 25%;
		top: 17%;
		backface-visibility: hidden;
		perspective: 300px;
		transition: transform 250ms ease-out;
		transform-style: preserve-3d;
		transform-origin: 0 0 0;
	}
}