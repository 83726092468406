@import "../../shared/styles/index.scss";

.CompetitionImage {
  position: relative;
  box-sizing: border-box;
  border-radius: 20px;
	@include background-opacity($color-black, 0.3);

  .CompetitionImage-cover {
    box-sizing: border-box;
    border-radius: 12px;
    width: 360px;
    height: 335px;
    background-size: cover;
    background-position: center center;
    position: relative;

    @include xLarge-desktop {
      width: 360px;
      height: 335px;
    }
  }
}
