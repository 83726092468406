@import "../../shared/styles/index.scss";

.Modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2000;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 99999;

	.Modal-container {
		position: absolute;
		top: calc(50% - 200px);
		left: calc(50% - 350px);
		width: 700px;
		//height:400px;
		border: 1px solid rgba(58, 58, 64, 0.3);
		z-index: 2100;
		animation: FadeIn 250ms linear;
		display: flex;
		flex-flow: column;
		box-shadow: 0 1px 84px 0 rgba(0, 0, 0, 0.13);
		background-color: $color-white;
		box-sizing: border-box;
		padding: 30px;
	}

	.Modal-close {
		position: absolute;
		top: 20px;
		right: 15px;

		background-color: transparent;
		border: 0;
		cursor: pointer;

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.Modal-title {
		@include font-source-sans-pro(32, $color-darkViolet, 400, 40);
		margin: 0 0 20px;
	}

	.Modal-content {
		position: relative;
		box-sizing: border-box;

		>button {
			margin: 20px 20px 0 0;
			min-width: auto;
			padding: 0 25px;

			&:last-child {
				margin: 20px 0 0;
			}
		}
	}
}

@keyframes MoveUpDown {
	0% {
		transform: translateY(-100vh);
	}

	50% {
		transform: translateY(0);
	}
}

@keyframes FadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}