@import "../../shared/styles/index.scss";

.Admin {
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 1440px;
  padding: 0 90px;
  margin: 60px auto 0;
  position: relative;

	.Admin-error {
		@include font-source-sans-pro(32, $color-red, 400);
		margin: 30px auto;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		max-width: 1440px;
		justify-content: center;
		min-height: 600px;
	}


  .Admin-leaderboard {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-right: 0;

    img{
      margin-left: -7px;
    }

    span {
      color: white;
    }
  }

  table {
    margin-top: 21px;
  }

}
