@import "../../shared/styles/index.scss";

.ProgressBar {
	position: relative;
	background-color: $color-darkViolet;
	height: 18px;
	box-sizing: border-box;
	width: 100%;
	border-radius: 11px;

	.ProgressBar-inner {
		width: 0;
		transition: width 250ms;
		background-color: $color-green;
		height: 18px;
		border-radius: 11px;
	}
}
