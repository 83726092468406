@import "../../shared/styles/index.scss";

.CompetitionCard {
  position: relative;
  margin: 0 20px 20px 0;
  box-sizing: border-box;
  width: 380px;
  height: 355px;
  border-radius: 20px;
  transition: background-color 300ms;

  @include xLarge-desktop {
    margin: 0 30px 30px 0;
  }

  &:hover {
    @include background-opacity($color-white, 0.1);

		.CompetitionCard-overlay {
			opacity: 1;
		}

    .CompetitionCard-content {
      opacity: 1;
    }
  }

  .CompetitionCard-kind {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    left: 20px;
  }

	.CompetitionCard-title {
		@include font-righteous(26, $color-white, 400, 32);
		border-left: 7px solid $color-pink;
		position: absolute;
		bottom: 30px;
		left: 0;
		margin: 0;
		max-width: 190px;
		padding: 0 0 0 20px;
	}

	&.CompetitionCard--violet {

		.CompetitionCard-kind {
			background-color: $color-pink;
		}

		.CompetitionCard-title {
			border-color: $color-pink;
		}
	}

	&.CompetitionCard--lightBlue {
		.CompetitionCard-kind {
			background-color: $color-lightBlue;
		}

		.CompetitionCard-title {
			border-color: $color-lightBlue;
		}
	}

  .CompetitionCard-cover {
    box-sizing: border-box;
    border-radius: 12px;
    width: 360px;
    height: 335px;
    background-size: cover;
    background-position: center center;
    margin: 10px;
    position: relative;

    @include xLarge-desktop {
      width: 360px;
      height: 335px;
    }
  }

	.CompetitionCard-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 360px;
		height: 335px;
		background-color: $color-blue;
		box-sizing: border-box;
		padding: 20px 28px;
		border-radius: 12px;
		opacity: 0;
		transition: opacity 400ms;

		@include xLarge-desktop {
			width: 360px;
			height: 335px;
		}
	}

  .CompetitionCard-content {
    opacity: 0;
    transition: opacity 1200ms;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
		height: 100%;

    .CompetitionCard-title {
      border: 0;
      padding: 0;
      position: static;
      margin: 0 0 10px 0;
    }
  }

  .CompetitionCard-description {
    @include font-source-sans-pro(14, $color-white, 400, 20);
    margin: 0;
  }

  .CompetitionCard-list {
    display: flex;
    margin: 12px 0 4px;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    list-style: none;
  }

  .CompetitionCard-item {
    width: 95px;
    height: 105px;
    border-radius: 2px;
    margin: 2px;
    @include background-opacity($color-white, 0.1);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    @include font-righteous(12, $color-white);

    @include xLarge-desktop {
      width: 100px;
    }

    a {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      @include font-righteous(12, $color-white);
      text-decoration: none;
    }

    img {
      margin: 0 0 10px 0;
    }
  }
}
