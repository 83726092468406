@import "../../shared/styles/index.scss";

.List {
  width: 100%;

  .List-title {
    @include font-righteous(22, $color-white, 400);
    margin: 0 0 10px 0;

    &.List-title--uppercase {
      text-transform: uppercase;
    }
  }

  .List-description {
    @include font-source-sans-pro(18, $color-white, 400);
    margin: 0 0 10px 0;
  }

  .List-items {
    list-style: none;
    padding: 10px 0 0;
  }

  .List-item {
    display: flex;
    align-items: center;
    padding: 25px 0;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    justify-content: space-between;

    &:last-child {
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }

    > div {
      margin: 0 70px 0 0;
      max-width: 350px;
      box-sizing: border-box;
    }

    span {
      display: block;
      @include font-righteous(18, $color-white, 400);

      &:nth-child(2) {
        @include font-source-sans-pro(16, $color-white, 400, 22);
        margin: 5px 0 0;
      }
    }
  }

  .List-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-pink;
    text-transform: uppercase;
    @include font-source-sans-pro(14, $color-white, 700);
    padding: 10px 20px;
    min-width: 190px;
    box-sizing: border-box;
    text-decoration: none;
    transition: opacity 250ms;
    border-radius: 22px;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }

    > img {
      margin: 0 10px 0 0;
    }
  }

  div.List-button {
    cursor: pointer;
    margin: 0;
  }
}
