@import "../../shared/styles/index.scss";

.Checkbox {
  display: flex;
  align-content: space-between;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  margin: 0 0 20px;

  & input {
    height: 50px;
    padding: 0 25px;
    border-radius: 5px;
    border: none;
    background-color: $color-darkViolet;
    outline: none;
    box-shadow: none;
    color: $color-white;
    max-width: 355px;

    &::placeholder {
      color: rgba($color-white, 0.21);
    }

    &[type="checkbox"] {
      -webkit-appearance: none;
      width: 35px;
      height: 35px;
      border: 1px solid rgba($color-white, 0.21);
      padding: 0;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      cursor: pointer;

      &::before {
        content: ".";
        height: 0;
        width: 0;
        background-color: transparent;
        border-radius: 2px;
        transition: all 100ms ease-in;
        color: transparent;
      }

      &:checked {
        &::before {
          content: ".";
          height: 25px;
          width: 25px;
          background-color: $color-green;
          transition: all 100ms ease-in;
        }
      }
    }
  }

  & label {
    font-size: 22px;
    font-weight: 300;
    color: white;
    margin: 0 20px;
  }

  & .Checkbox--small--label {
    font-size: 18px;
    opacity: 0.5;
    transition: opacity 100ms ease-in;
  }

  &:hover {
    & .Checkbox--small--label {
      opacity: 1;
    }
  }

  & a {
    margin: 16px 0;
  }
}