@import "../../shared/styles/index.scss";

.EditorTextArea {
	position: relative;

	.EditorTextArea-label {
		@include font-source-sans-pro(22px, $color-white, 400);
		display: block;
		margin: 0 0 20px 0;
	}

	.EditorTextArea-wrapper{
		background-color: $color-darkViolet;
		padding: 20px;
		box-sizing: border-box;
		border-radius: 5px;
		height: 240px;
		overflow-y: hidden;

		.EditorTextArea-toolbar{
			border: 0;
			background-color: transparent;
			padding: 0;
			margin: 0 0 12px 0;
		}

		.EditorTextArea-button {
			border: 1px solid rgba($color-violet, 0.5);
			background-color: $color-violet;
			margin: 0 4px 0 0;
			box-shadow: none;
			transition: background-color 300ms ease-in-out;

			&:hover {
				border: 1ox solid rgba($color-darkViolet, 0.5);
			}
		}

		.EditorTextArea-editor{
			overflow-y: auto;
			color: $color-white;
			padding: 0;
			@include font-source-sans-pro(14, $color-white, 400);

			&.EditorTextArea-editor--withToolbar {
				height: calc(100% - 50px);
			}
		}
	}
}
