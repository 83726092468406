.react-datepicker-wrapper{
  input{
    background: #373f89;
    color: rgba(255, 255, 255, 0.6);
    border: none;
    border-radius: 5px;
    padding: 15px;
    font-size: 12px;
  }
}
