@import "../../../shared/styles/index.scss";
@import "../styles.module.scss";

.SelectInput {
  position: relative;
  text-align: left;
  z-index: 200;
  @include font-source-sans-pro(18, $color-white, 400);

  & label {
    @include font-source-sans-pro(22, $color-white, 300);
    margin-bottom: 21px;
    display: block;
  }

  &.is-open {
    .SelectInput-button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .SelectInput-arrow {
      transform: rotate(180deg);
    }
  }

  &.is-disabled {
    .SelectInput-button {
      opacity: 0.5;
      cursor: default;
    }
  }

  .SelectInput-arrow {
    pointer-events: none;
    transition: all 300ms ease-out;
  }

  .SelectInput-delete {
    position: absolute;
    right: 60px;
    top: 15px;
    pointer-events: none;
  }

  .SelectInput-button {
    border: 0;
    @include font-source-sans-pro(14, $color-white, 400);
    background-color: $color-darkViolet;
    border-radius: 3px;
    padding: 15px 28px;
    @include color-opacity($color-white, 0.6);
    width: 100%;
    cursor: pointer;
    height: 50px;
    text-align: left;

    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-items: center;

    z-index: 9999;

    * {
      pointer-events: none;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .SelectInput-list {
    position: absolute;
    top: 90px;
    left: 0;
    box-sizing: border-box;
    background-color: rgba($color-darkViolet, .8);
    max-height: 0;
    overflow: hidden;
    width: 100%;
    border-top: 1px solid rgba(white, .1);
    transition: all 250ms ease-out;

    &.is-visible {
      max-height: 270px;
      overflow: hidden;
    }
  }
}