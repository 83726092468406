@import '../../shared/styles/index.scss';

.UploadBoxContainer {
    width: 100%;
}

.UploadedFiles {
    margin-bottom: 20px;
    font-weight: bold;

    .UploadedFile {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(black, 0.2);
        color: $color-white;

        img {
            cursor: pointer;
        }
    }
}

.UploadBox {
    width: 100%;
    height: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    @include background-opacity($color-black, 0.1);
    @include font-source-sans-pro(16, false, 400, 22);
    cursor: pointer;
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &.is-disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    .UploadBox-image {
        margin: 0 30px 0 0;
    }

    .UploadBox-title {
        display: flex;
        align-items: center;
        @include font-source-sans-pro(22, $color-white, 400);
    }

    .UploadBox-description {
        display: block;
        @include color-opacity($color-white, 0.5);
        margin: 20px 0 0;
        max-width: 300px;
        text-align: center;
    }

    .is-active {
        border: 2px dashed $color-pink;
    }
}