@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin color-opacity($color, $opacity: 0.3) {
  color: rgba($color, $opacity);
}

@mixin border($top, $right, $bottom, $left, $type, $color) {
  border: $top $right $bottom $left $type $color;
}

.u-fullWidth {
  width: 100% !important;
}

.u-shrinkAll>* {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}

@mixin flexyfyme ($ac: center, $jc: center, $ai: center, $ji: center, $dir: row, $display: flex, $wrap: wrap) {
  display: $display;
  align-content: $ac;
  justify-content: $jc;
  align-items: $ai;
  justify-items: $ji;
  flex-wrap: $wrap;
  flex-direction: $dir;
}