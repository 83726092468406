@import "../style.module.scss";

.AdminNewCompetitionChoose {
	position: relative;
	padding: 60px 0 30px;

	.AdminNewCompetitionChoose-label {
		@include font-source-sans-pro(22px, $color-white, 400, 28);
		display: block;
		margin: 0 0 20px 0;
		text-align: left;
	}
}
