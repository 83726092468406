@import "../../shared/styles/index.scss";
.Table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .Table {
    width: 100%;
    color: white;
    text-align: left;
    thead {
      @include background-opacity(#000000, 0.1);
    }
    th {
      padding: 16px 19px;
      font-family: sans-serif;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: bold;
      border-top: 2px solid #00000038;
      border-bottom: 2px solid #00000038;
    }
    td {
      padding: 16px 19px;
      border-bottom: 2px solid #00000038;
    }
  }

	button {
		background-color: transparent;
		border: 0;
		cursor: pointer;
		transition: opacity 250ms;

		&:hover {
			opacity: 0.7;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}
}
