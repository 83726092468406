@import "../../shared/styles/index.scss";

.Competitions {
	min-height: 100vh;
	box-sizing: border-box;
	max-width: 1440px;
	padding: 20px 110px;
	margin: 0 auto;
	position: relative;

	@include xLarge-desktop {
		padding: 20px 90px;
	}

	.Competitions-image {
		position: absolute;
		z-index: -1;

		&.Competitions-image--orange {
			left: 420px;
			top: -40px;
			max-width: 150px;
			animation: imageAnim1 60s infinite ease-in-out;
		}

		&.Competitions-image--opacity {
			left: 720px;
			top: 70px;
			max-width: 70px;
			animation: imageAnim2 30s infinite ease-in-out;
		}

		&.Competitions-image--blue {
			left: 1020px;
			top: -15px;
			max-width: 150px;
			animation: imageAnim1 30s infinite ease-in-out;
		}

		&.Competitions-image--green {
			left: -90px;
			top: 585px;
			max-width: 60px;
			animation: imageAnim2 60s infinite ease-in-out;
		}

		&.Competitions-image--violet {
			right: 0;
			top: 635px;
			max-width: 150px;
			animation: imageAnim1 30s infinite ease-in-out;
		}
	}

	.Competitions-content {
		margin: 50px 0 100px;
		box-sizing: border-box;
		z-index: 1050;
		min-height: 400px;

		&.Competitions-content--error {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.Competitions-background {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		height: 100px;
		width: 100%;
		background-image: linear-gradient(to bottom, rgba(57, 81, 158, 0), $color-violet);
	}

	.Competitions-title {
		max-width: 485px;
		z-index: 1000;
  }

  .Competitions-list {
    display: flex;
    flex-wrap: wrap;
  }

  .Competitions-feedback {
		@include font-source-sans-pro(25, $color-white, 400);
    margin: 30px auto;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .Loader {
      margin: 0 40px 0 0;
    }
  }

	.Competitions-error {
		@include font-source-sans-pro(32, $color-red, 400);
		margin: 30px auto;
		box-sizing: border-box;
		display: flex;
		align-items: center;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes imageAnim1 {
	0% {
		transform: translate3d(0, 0, 0) scale(1) skewY(0);
	}

	25% {
		transform: translate3d(-2rem, 0, 0) scale(1.1);
	}

	50% {
		transform: translate3d(-1rem, 2rem, 0) scale(1.1) skewY(10deg);
	}

	75% {
		transform: translate3d(1rem, -2rem, 0) scale(1.1);
	}

	100% {
		transform: translate3d(0, 0, 0) scale(1) skewY(0);
	}
}

@keyframes imageAnim2 {
	0% {
		transform: translate3d(0, 0, 0) scale(1) skewY(0);
	}

	25% {
		transform: translate3d(2rem, 0, 0) scale(1.1);
	}

	50% {
		transform: translate3d(1rem, -2rem, 0) scale(1.1) skewY(10deg);
	}

	75% {
		transform: translate3d(-1rem, 2rem, 0) scale(1.1);
	}

	100% {
		transform: translate3d(0, 0, 0) scale(1) skewY(0);
	}
}
