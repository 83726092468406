@import "../../shared/styles/index.scss";


	.Competition-registrations {
		list-style: none;
		display: flex;
		padding: 20px 0;
		align-items: center;
		border-top: 1px solid rgba(0, 0, 0, 0.3);
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);

		>li {
			@include font-righteous(16, $color-white, 400);
			display: flex;
			align-items: center;
			margin: 0 75px 0 0;

			>img {
				margin: 0 20px 0 0;
			}

			&:last-child {
				img {
					margin: 0 50px 0 0;
				}
			}
		}
	}

	.Competition-course {
		@include font-source-sans-pro(16, $color-white, 400, 28);
		display: block;
	}

	.Competition-teacher {
		@include font-source-sans-pro(16, $color-white, 400, 28);
		margin: 0 0 10px 0;
		display: block;
	}

	.Competition-duration {
		@include font-source-sans-pro(16, $color-white, 400, 28);
		display: block;
	}

	.Competition-content {
		box-sizing: border-box;
		padding: 20px 110px;
		max-width: 1440px;
		margin: 0 auto;

		@include xLarge-desktop {
			padding: 20px 105px;
		}
	}

	.Competition-gradient {
		//background: linear-gradient(90deg, $color-darkViolet 50%, $color-pink 50%);
		background-color: $color-darkViolet;
		z-index: 999;
	}

	.Competition-navigation {
		background-color: $color-darkViolet;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		height: 80px;
		width: 100%;
		max-width: 1440px;
		padding: 0 125px;
		margin: 0 auto;
		z-index: 1000;

		@include xLarge-desktop {
			padding: 0 105px;
		}
	}

	.Competition-submissions {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		div {
			max-width: 500px;
			margin: 0 0 20px 0;

			@include xLarge-desktop {
				max-width: 600px;
			}
		}
	}

	.Competition-time {
		background-color: $color-pink;
		padding: 0 1rem;
		min-width: 16.25rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		@include font-righteous(22, $color-white, 400);
		box-sizing: border-box;
		//padding: 0 0 0 25px;

		>img {
			margin: 0 25px 0 0;
		}
	}

	.Competition-small {

		display: block;
		@include font-source-sans-pro(16, false, 400);
		@include color-opacity($color-white, 0.5);
	}

	.Competition-otherGames {
		display: flex;
		align-items: center;
		overflow-x: auto;
		margin: 0 10px 0 -10px;
		padding: 0 0 20px;
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		&::-webkit-scrollbar {
			width: 0 !important
		}
	}

	.Competition-submission-status {
		color: $color-orange;
		font-weight: bold;

		&.is-error {
			color: $color-red;
		}
	}


.Competition-feedback {
	@include font-source-sans-pro(25, $color-white, 400);
	margin: 30px auto;
	border-radius: 3px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	.Loader {
		margin: 0 40px 0 0;
	}
}