@import "../../shared/styles/index.scss";

.Welcome {
  position: relative;
  height: 100%;

  .Welcome-content {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;

    box-sizing: border-box;
    padding: 20px 30px 20px 110px;
    max-width: 1440px;
    margin: 0 auto;
    min-height: 1px;

    @include xLarge-desktop {
      padding: 20px 30px 20px 90px;
    }
  }

  .Welcome-column {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    align-content: center;
    justify-content: center;
    opacity: 0;
    animation: overlayIntro 1s ease-out;
    animation-fill-mode: forwards;
    min-height: 1px;

    &:nth-child(2) {
      flex-direction: row;
    }
  }

  $elements: 2;

  @for $i from 0 to $elements {
    & .Welcome-column:nth-child(#{$i + 1}) {
      animation-delay: #{$i + 0.5}s;
    }
  }
}

@keyframes overlayIntro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}