.FormulaChoice-radio{
  margin: 60px 0;
}
.FormulaChoice-radioItem{
  display: inline-block;
  width: 24%;
  margin-right: 1%;
  .FormulaChoice-radioItemLatex{
    margin-top: 30px;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.37);
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    cursor: pointer;
    color: white;
  }
  &.active{
    .FormulaChoice-radioItemLatex{
      border-radius: 5px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.37);
    }
  }
}
.FormulaChoice-radioItemLabel{
  opacity: 0.6;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}
