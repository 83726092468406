@import "../../shared/styles/index.scss";

.Box {
	@include background-opacity(#000, 0.23);
	padding: 40px;
	border-radius: 5px;
	box-sizing: border-box;
	max-width: 500px;

	@include xLarge-desktop {
		max-width: 600px;
	}

	.Box-title {
		text-transform: uppercase;
		@include font-righteous(22, $color-white, 400);
		margin: 0 0 30px 0;
	}

	.Box-content {
		font-style: italic;
		@include font-source-sans-pro(16, $color-white, 400);
	}
}
