@import "../../../../shared/styles/index.scss";

.UploadDataset{
	margin-top: 60px;
}
.UploadDataset-title{
	margin-bottom: 20px;
	display: inline-block;
}
.UploadDataset-subtitle{
	@include font-source-sans-pro(18, $color-white, 400);
	@include color-opacity($color-white, 0.6)
}
.UploadDataset-text{
  margin-top: 30px;
}

.RegressionUploadDataset-imageContainer{
  margin: 60px 0;
  display: flex;
  align-items: center;
  .RegressionUploadDataset-image{
    width: 60%;
  }
  .RegressionUploadDataset-imageDescription{
    margin-left: 80px;
    .RegressionUploadDataset-imageDescriptionItem{
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(black, 0.3);
      &:last-child{
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .AdminSectionTitle{
        margin-bottom: 10px;
      }
    }
  }
}
