// Colors
$color-white: #fff;
$color-dark-grey: #010101;
$color-black: #000000;
$color-violet: #354fa3;
$color-darkViolet: #2e418a;
$color-blue: #011630;
$color-lightBlue: #81b5f6;
$color-orange: #ffa558;
$color-pink: #e563bb;
$color-green: #7ed321;
$color-red: #d0021b;
