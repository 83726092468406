@import "../../shared/styles/index.scss";

.Navigation {
  list-style: none;
  padding: 0;
  display: flex;
  box-sizing: border-box;

  .Navigation-item {
    margin: 0 90px 0 0;

    @include xLarge-desktop {
      margin: 0 120px 0 0;
    }
  }

  .Navigation-link {
    @include font-righteous(22, $color-white, 400);
    text-transform: uppercase;
    text-decoration: none;
    @include color-opacity($color-white, 0.3);
    transition: color 250ms;
    cursor: pointer;

    &:hover {
      @include color-opacity($color-white, 0.6);
    }

    &.is-active {
      color: $color-white;

      &:hover {
        color: $color-white;
      }
    }
  }
}
