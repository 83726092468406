@import "../../shared/styles/index.scss";

.Paragraph {
  .Paragraph-separator {
    width: 40px;
    margin: 0 0 10px 0;
  }

  .Paragraph-description {
    @include font-source-sans-pro(18, $color-white, 400, 26);
  }

  &.Paragraph--large {
    .Paragraph-separator {
      width: 60px;
      margin: 0 0 30px 0;
    }

    .Paragraph-description {
      @include font-source-sans-pro(22, $color-white, 400, 30);
    }
  }
}
