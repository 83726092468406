@import "../../shared/styles/index.scss";

.AdminNewCompetition,
.AdminNewCompetitionChoose,
.AdminNewCompetitionDetails,
.AdminNewCompetitionDatasetUpload,
.AdminNewCompetitionTeamStudents {
  position: relative;

  & label {
    font-size: 22px;
    font-weight: 300;
  }

  .AdminNewCompetition-backButton {
    @include font-righteous(14, $color-white, 400);
    @include color-opacity($color-white, 0.4);
    text-decoration: none;
    transition: color 250ms;
    display: block;
    margin: 0 0 25px;
    text-transform: uppercase;

    &:hover {
      @include color-opacity($color-white, 1);
    }
  }

  .AdminNewCompetition-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1440px;
    padding: 20px 90px;
    margin: 0 auto;
    position: relative;
  }

  .AdminNewCompetition--row {
    width: 100%;
    display: inline-flex;
    align-content: space-between;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 64px;
  }

  .AdminNewCompetition--centered {
    align-content: center;
    justify-content: center;
  }

  .AdminNewCompetition--column {
    color: $color-white;
    width: 45%;
    display: inline-flex;
    align-content: space-between;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    &.full-width {
      width: 100%;
    }

    &.align-start {
      align-content: flex-start;
      align-items: flex-start;
      justify-items: flex-start;
      justify-content: flex-start;
    }
  }

  .Paragraph {
    max-width: 100%;
  }
}

.InputTemplate {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  flex-grow: 1;
  width: 100%;
  margin: 0 0 20px;

  & input {
    height: 50px;
    padding: 0 25px;
    border-radius: 5px;
    border: none;
    background-color: $color-darkViolet;
    outline: none;
    box-shadow: none;
    color: $color-white;
    max-width: 355px;

    &::placeholder {
      color: rgba($color-white, 0.21);
    }

    &[type="checkbox"] {
      -webkit-appearance: none;
      width: 35px;
      height: 35px;
      border: 1px solid rgba($color-white, 0.21);
      padding: 0;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      cursor: pointer;

      &::before {
        content: " ";
        height: 0;
        width: 0;
        background-color: transparent;
        border-radius: 2px;
        transition: all 100ms ease-in;
      }

      &:checked {
        &::before {
          content: " ";
          height: 25px;
          width: 25px;
          background-color: $color-green;
          transition: all 100ms ease-in;
        }
      }
    }
  }

  & label {
    font-size: 22px;
    font-weight: 300;
  }

  &.InputTemplate--vertical {
    flex-direction: column;

    & label {
      display: inline-flex;
      flex-grow: 1;
      width: 100%;
      text-align: center;
      margin-bottom: 16px;
    }

    & input {
      display: inline-flex;
      flex-grow: 1;
      width: calc(100% - 32px);
      max-width: 100%;
    }

    &.InputTemplate--reverse {
      flex-direction: column-reverse;
      margin-bottom: 0;
      margin-top: 16px;
    }
  }

  &.InputTemplate--start {
    justify-items: flex-start;
    justify-content: flex-start;

    &.InputTemplate--reverse {
      justify-items: flex-end;
      justify-content: flex-end;
    }
  }

  &.InputTemplate--end {
    justify-items: flex-end;
    justify-content: flex-end;

    &.InputTemplate--reverse {
      justify-items: flex-start;
      justify-content: flex-start;
    }
  }

  &:not(.InputTemplate--vertical) {
    & label {
      display: inline-flex;
      flex-shrink: 1;
      margin-right: 16px;
    }

    & input {
      display: inline-flex;
      flex-grow: 1;
    }

    &.InputTemplate--reverse {
      flex-direction: row-reverse;

      & label {
        display: inline-flex;
        flex-shrink: 1;
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  & .InputTemplate--small--label {
    font-size: 18px;
    opacity: 0.5;
    transition: opacity 100ms ease-in;
  }

  &:hover {
    & .InputTemplate--small--label {
      opacity: 1;
    }
  }

  & a {
    margin: 16px 0;
  }
}