@import "../../shared/styles/index.scss";

.Section {
  color: $color-white;
  background-color: $color-violet;
  box-sizing: border-box;

  &.Section--dark {
    background-color: $color-darkViolet;
  }

  &.Section--inline {
    .Section-content {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

			> div {
				width: 49%;
			}
    }
  }

  .Section-content {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 125px;
    max-width: 1440px;

    @include xLarge-desktop {
      padding: 0 105px;
    }
  }
}
