@import "../style.module.scss";

.AdminNewCompetitionDatasetUpload {
	padding: 40px 0;

	.AdminNewCompetitionDatasetUpload-upload {
		width: 100%;
		margin: 0 auto;
	}

	.AdminNewCompetitionDatasetUpload-download {
		display: block;
		text-align: center;
		margin: 20px auto 160px;
		@include font-source-sans-pro(18px $color-green, 400);
		transition: opacity 250ms;

		&:hover {
			opacity: 0.8;
		}
	}

	.AdminNewCompetitionDatasetUpload-status {
		color: $color-white;
		font-size: 14pt;
		margin-top: 1rem;
	}

	.AdminNewCompetitionDatasetUpload-error {
		color: $color-red;
		font-size: 14pt;
		margin-top: 1rem;
	}
}