.ClassificationFormulaChoice{
  display: flex;
  justify-content: space-between;
	align-items: flex-start;

  .ClassificationFormulaChoice-categories{
		margin: 60px 0 20px;
  }
  .ClassificationFormulaChoice-formula-layout{
    display: flex;
    margin-top: 30px;
  }
  .ClassificationFormulaChoice-formula-container{
    border-right: 1px solid white;
    padding-right: 10px;
    margin-right: 60px;
  }
  .ClassificationFormulaChoice-flex-layout{
    display: flex;
  }
  .ClassificationFormulaChoice-formula-layout-text{
  }
  .ClassificationFormulaChoice-standardize{
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .ClassificationFormulaChoice-matrixContainer{
		max-width: 450px;
  }
  .ClassificationFormulaChoice-matrixTitle{
    margin-bottom: 10px;
  }
  .ClassificationFormulaChoice-matrixSubtitle{
    margin-bottom: 60px;
  }
  .ClassificationFormulaChoice-matrixDescription{
    margin-top: 30px;
  }
}
.Latex {
  margin: 60px 0;
}
.Latex-item {
  display: inline-block;
  width: 250px;
  margin-top: 40px;
  margin-bottom: 30px;
  .Latex-itemLabel {
    opacity: 0.6;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }
  .Latex-itemLatex {
    margin-top: 30px;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.37);
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    color: white;
  }
}
