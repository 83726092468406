@import "../../shared/styles/index.scss";

.Title {
  @include font-righteous(32, $color-white, 400, 44, 1.5);
  margin: 0 0 25px;

	&.Title--medium {
		@include font-righteous(52, $color-white, 400, 57);
	}

  &.Title--large {
    @include font-righteous(61, $color-white, 400, 66, 1.5);
  }
}
