@import "../../shared/styles/index.scss";

.AdminCompetition {
	position: relative;

	.AdminCompetition-header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: baseline;
		margin: 0 0 30px;
	}

	.AdminCompetition-info {
		display: flex;
		align-items: center;
		margin: 0 0 50px;
		list-style: none;

		img {
			margin: 0 20px 0 0;
		}

		> li {
			display: flex;
			align-items: center;
			margin: 0 80px 0 0;

			&:last-child {
				margin: 0;
			}
		}

		span {
			display: inline-block;
			@include font-righteous(16, $color-white, 400);
		}
	}

	.AdminCompetition-backButton {
		@include font-righteous(14, $color-white, 400);
		@include color-opacity($color-white, 0.4);
		text-decoration: none;
		transition: color 250ms;
		display: block;
		margin: 0 0 25px;
		text-transform: uppercase;

		&:hover{
			@include color-opacity($color-white, 1);
		}
	}

	.AdminCompetition-content {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		max-width: 1440px;
		padding: 20px 90px;
		margin: 0 auto;
		position: relative;
	}
}

.AdminCompetition-feedback {
	@include font-source-sans-pro(25, $color-white, 400);
	margin: 30px auto;
	border-radius: 3px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	.Loader {
		margin: 0 40px 0 0;
	}
}
