.MatrixContainer{
  color: white;
  position: relative;
  margin-top: 40px;

  .MatrixLabelW{
    position: absolute;
    left: -40px;
  }
  .MatrixLabelReal{
    position: absolute;
    width: 100%;
    text-align: center;
    top: -25px;
  }
  .MatrixLabelForecast{
    position: absolute;
    transform: rotate(-90deg);
    top: 50%;
    left: -45px;
  }
}
.Matrix {
  width: 450px;
  height: 450px;
  border: 1px solid #373f89;
  .MatrixHeader{
    cursor: not-allowed;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    .MatrixIndexLabel {
      color: #373f89;
    }
  }
  .MatrixIndex{
    cursor: not-allowed;
    height: 100%;
    width: 100%;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid #373f89;
    color: white;
    box-sizing: border-box;
  }
  .MatrixIndexLabel{
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(white, 0.7);
  }
  .MatrixHeaderYHat{
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 7px;
    bottom: 5px;
    align-items: flex-end;
    justify-content: center;
    span{ color: #373f89; }
  }
  .MatrixHeaderY {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 7px;
    right: 5px;
    align-items: center;
    justify-content: flex-end;
    span { color: #373f89; }
  }
}
