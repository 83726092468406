.MatrixInput{
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #373f89;
  color: white;
  display: inline-block;
  justify-content: center;
  text-align: center;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: top;
  &.disabled{
    cursor:not-allowed;
    background: #373f89;
    color: rgba(255, 255, 255, 0.6);
  }
}
.MatrixInput::-webkit-outer-spin-button,
.MatrixInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
