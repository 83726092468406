.Cover {
  //height: 440px;
  position: relative;

  .Cover-background {
    opacity: 0.3;
    background-position: center center;
    background-size: auto;
    background-color: #232323;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .Cover-content {
    position: relative;
  }
}
