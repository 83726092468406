@import '../../../../shared/styles/index.scss';

.UploadedDatasets {
  @include flexyfyme(flex-start, flex-start, center, center);
  width: 100%;

  & img {
    display: inline-flex;
    height: 100%;
    width: auto;
    flex-shrink: 1;
    margin-right: 1.5rem;
  }

  & .UploadedDatasets-list {
    list-style-type: none;
    flex-grow: 1;
    height: 100%;
    @include flexyfyme(space-between, space-between, center, center, column);

    & .UploadedDatasets-list-item {
      height: 3.125rem;
      border: .1rem solid $color-darkViolet;
      border-radius: 5px;
      padding: 0 1rem;
      background: rgba($color-black, 0.1);
      @include flexyfyme(space-between, space-between, center, center, row, flex, none);
      width: calc(100% - 2.2rem);
      cursor: pointer;

      &.is-disabled {
        color: rgba($color-white, .5);
        cursor: not-allowed;

        & a {
          pointer-events: none;
          touch-action: none;
          cursor: not-allowed;
          opacity: .5;
        }
      }

      & a {
        & img {
          height: 100%;
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }
}