/* Phones (landscape phones, 576px and up) */
@mixin mobile {
  @media (min-width: 576px) {
    @content;
  }
}

/* Tablets (tablets, 768px and up) */
@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

/* Desktops (desktops, 992px and up) */
@mixin desktop {
  @media (min-width: 992px) {
    @content;
  }
}

/* Large desktops (large desktops, 1200px and up) */
@mixin large-desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

/* Extra large desktops (extra large desktops, 1440px and up) */
@mixin xLarge-desktop {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin height-900 {
  @media (max-height: 900px) {
    @content;
  }
}

@mixin height-600 {
  @media (max-height: 600px) {
    @content;
  }
}