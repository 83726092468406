@import '../../shared/styles/index.scss';

.Tabs {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;

  border-bottom: 2px solid $color-white;
  padding: 0 0 16px 0;
  margin: 0 0 40px 0;

  .Tabs-selector {
    width: 100%;
    display: flex;

    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: center;
    justify-items: center;

    & button {
      @include tablet {
        font-size: 8pt;
        width: 200px;
        height: 40px;
      }

      @include desktop {
        width: 221px;
        height: 44px;

        font-size: 14px;
      }
    }
  }

  .Tabs-separator {
    position: relative;
    top: -43px;
    left: 221px;
    width: calc(100% - 442px);
    height: 5px;
    z-index: -1;

    &:before {
      width: 100%;
      height: 100%;
      content: " ";
      display: block;
      border-bottom: 3px dotted $color-white;
      margin-top: -3px;
      opacity: .1;
    }
  }

  .Section {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}