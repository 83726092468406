@import "../../shared/styles/index.scss";

.Header {
  
  .Logout {
    color: white;
    margin-right: 50px;

    a {
      color: white;
      margin-left: 5px;
    }
  }

  .AuthGateContainer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    width: 100%;
    max-width: 1440px;
    margin: auto;
  }
}