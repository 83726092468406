@import "../style.module.scss";

.AdminNewCompetitionTeamStudents {

	.AdminNewCompetitionTeamStudents-label {
		@include font-source-sans-pro(22, $color-white, 400);
		display: block;
		margin: 0 0 10px 0;

		&.AdminNewCompetitionTeamStudents-label--small {
			font-size: 14px;
			line-height: 20px;
			margin: 0 0 20px 0;
			@include color-opacity($color-white, 0.6);
		}
	}

	.AdminNewCompetitionTeamStudents-column {
		display: flex;
		align-items: center;
		justify-items: center;
		flex-wrap: wrap;
		align-content: space-between;
		justify-content: space-between;

		div {
			&:nth-child(3) {
				flex-basis: 100%;
			}
		}

		>button {
			flex-basis: 100%;
			margin: 50px 0 0;
		}
	}

	.AdminNewCompetitionTeamStudents-table {
		max-width: 890px;
		margin: 0 auto 30px;

		>label {
			margin: 0 0 30px 0;
		}
	}

	& img {
		cursor: pointer;
	}
}