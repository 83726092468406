@import "../style.module.scss";

.AdminNewCompetitionDetails {
	.AdminNewCompetitionDetails-label {
		@include font-source-sans-pro(22px, $color-white, 400, 28);
		display: block;
		margin: 0 0 20px 0;

		&.AdminNewCompetitionDetails-label--small {
			font-size: 14px;
			line-height: 20px;
			@include color-opacity($color-white, 0.6);
			margin: 40px 0;
		}
	}

	.AdminNewCompetitionDetails-katex {
		margin-bottom: 3rem;
		> div > span {
			text-align: left;
		}
	}

	.AdminNewCompetitionDetails-uploadWorkflow {
		max-width: 480px;
		margin: 0 0 40px 0;

		> div {
			display: flex
		}

		@include desktop {
			max-width: 50%;
		}
	}

	.AdminNewCompetitionDetails-dates {
		max-width: 480px;
		display: flex;

		@include desktop {
			max-width: 50%;
		}
	}

	.AdminNewCompetitionDetails-footer {
		padding: 40px 0 0;

		button, a {
			margin: 0 20px 20px 0;

			&:last-child {
				margin: 0 0 20px 0;
			}
		}
	}
}
