/* Phones (landscape phones, 576px and up) */
/* Tablets (tablets, 768px and up) */
/* Desktops (desktops, 992px and up) */
/* Large desktops (large desktops, 1200px and up) */
/* Extra large desktops (extra large desktops, 1440px and up) */
/* Fonts */
.u-fullWidth {
  width: 100% !important; }

.u-shrinkAll > * {
  flex-grow: 0 !important;
  flex-shrink: 1 !important; }

/**
DEFINE HERE SHARED STYLES FOR INPUTTEMPLATE
*/
.Input {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  margin-bottom: 1.125rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 400; }
  .Input input {
    height: 50px;
    padding: 0 25px;
    border-radius: 5px;
    border: none;
    background-color: #2e418a;
    outline: none;
    box-shadow: none;
    color: #fff;
    max-width: 50%;
    font-size: 0.875rem;
    font-weight: lighter; }
    .Input input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.21);
      font-size: 0.875rem;
      font-weight: lighter; }
    .Input input[type="checkbox"] {
      -webkit-appearance: none;
      width: 35px;
      max-width: 35px;
      height: 35px;
      max-height: 35px;
      border: 1px solid rgba(255, 255, 255, 0.21);
      padding: 0;
      flex-grow: 0;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      background-position: center;
      background-size: 0;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: all 50ms ease-out; }
      .Input input[type="checkbox"]:checked {
        background-size: 90%;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAABZklEQVRoQ+2aMU7DQBBF/5A2RY6w9BhxgyQ3gJtAj8FgergJ3CDODRCmZ4+QghYGLVIUiBzISJFmY32X1mhn/vw33Rf0/JOe6wMF7rvDdLB8CWMRnAAYZebmQhXP9XGc/zXXRgertzD6eMejQCaZCfs1jkKbwRBn1WFcdM25UWDZhlnu4paCksi6iNOtBV62YXIAmeXs3Ppsn9DpXRGb9f+dDpav4VxU7vdJoIpe1EfxYSuBV22oALneJ4GA3twWsaLAtAE6mCW7RHRlCxEloh4b4A3yBj24M/QkokTUgItHKREloh7cGXoSUSJqwMWjlIgSUQ/uDD2JKBE14OJRSkSJqAd3hp5ElIgacPEoJaJE1IM7Q08iulpW70MIvY+RJC/LNjQCGRuOwK1UofO6iJ2JrP+iXE+5i0ziBkOcmqNcSzsSrimMJ5pXGE8F32G8rnTTT5QYp3Q7rB01poM7WqTbM7138Ase9ipI7Sk4dwAAAABJRU5ErkJggg=="); }
  .Input label {
    font-size: 22px;
    font-weight: 300;
    text-align: left; }
    .Input label span {
      text-align: left; }
  .Input.Input--vertical {
    flex-direction: column; }
    .Input.Input--vertical label {
      display: inline-flex;
      flex-grow: 1;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem; }
      .Input.Input--vertical label span {
        width: 100%; }
    .Input.Input--vertical input {
      display: inline-flex;
      flex-grow: 1;
      width: calc(100% - 32px);
      max-width: 100%; }
    .Input.Input--vertical.Input--reverse {
      flex-direction: column-reverse;
      margin-bottom: 0;
      margin-top: 1rem; }
      .Input.Input--vertical.Input--reverse label {
        margin-bottom: 0;
        margin-top: 1rem; }
  .Input.Input--start {
    justify-items: flex-start;
    align-items: flex-start; }
    .Input.Input--start.Input--reverse {
      justify-items: flex-start;
      justify-content: flex-start; }
    .Input.Input--start label {
      text-align: left; }
      .Input.Input--start label span {
        text-align: left; }
    .Input.Input--start input,
    .Input.Input--start button {
      text-align: right; }
  .Input.Input--shrink {
    flex-shrink: 1;
    width: auto; }
  .Input.Input--end {
    align-content: flex-end;
    align-items: center; }
    .Input.Input--end.Input--reverse {
      justify-items: flex-end;
      justify-content: flex-end; }
    .Input.Input--end label {
      text-align: right; }
      .Input.Input--end label span {
        text-align: right; }
    .Input.Input--end input,
    .Input.Input--end button {
      text-align: right; }
  .Input:not(.Input--vertical) label {
    display: inline-flex;
    flex-shrink: 1; }
  .Input:not(.Input--vertical) input {
    display: inline-flex;
    flex-grow: 1; }
  .Input:not(.Input--vertical).Input--reverse {
    flex-direction: row-reverse; }
    .Input:not(.Input--vertical).Input--reverse label {
      display: inline-flex;
      flex-shrink: 1;
      margin-right: 0;
      margin-left: 16px; }
  .Input .Input--small--label {
    padding-left: 15px;
    font-size: 18px;
    opacity: 0.6; }
  .Input a {
    margin: 16px 0; }
  .Input.is-disabled input,
  .Input.is-disabled button,
  .Input.is-disabled div:first-of-type {
    opacity: .8;
    cursor: not-allowed !important;
    pointer-events: none;
    touch-action: none; }
  .Input.is-disabled label {
    opacity: .5;
    cursor: not-allowed; }
  .Input.is-required label::after {
    content: '*';
    color: #d0021b;
    position: relative;
    top: 0;
    margin: 0 5px; }

.AutoCompleteTextInput {
  position: relative;
  bottom: -5.9rem;
  z-index: 9999;
  width: 100%;
  max-width: 80%;
  background-color: transparent;
  margin-bottom: 0; }
  .AutoCompleteTextInput input {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    margin-bottom: .2rem; }
  .AutoCompleteTextInput.is-disabled {
    cursor: not-allowed;
    width: 100%;
    max-width: 100%;
    opacity: 1; }

.Input-label {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  display: inline-flex;
  flex-grow: 1; }
  .Input-label span {
    display: inline-block;
    overflow: hidden; }

.is-dark label {
  color: rgba(0, 0, 0, 0.31); }

.is-dark input {
  background-color: rgba(1, 1, 1, 0.21); }
  .is-dark input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff; }

/* Phones (landscape phones, 576px and up) */
/* Tablets (tablets, 768px and up) */
/* Desktops (desktops, 992px and up) */
/* Large desktops (large desktops, 1200px and up) */
/* Extra large desktops (extra large desktops, 1440px and up) */
/* Fonts */
.u-fullWidth {
  width: 100% !important; }

.u-shrinkAll > * {
  flex-grow: 0 !important;
  flex-shrink: 1 !important; }

/* Phones (landscape phones, 576px and up) */
/* Tablets (tablets, 768px and up) */
/* Desktops (desktops, 992px and up) */
/* Large desktops (large desktops, 1200px and up) */
/* Extra large desktops (extra large desktops, 1440px and up) */
/* Fonts */
.u-fullWidth {
  width: 100% !important; }

.u-shrinkAll > * {
  flex-grow: 0 !important;
  flex-shrink: 1 !important; }

/**
DEFINE HERE SHARED STYLES FOR INPUTTEMPLATE
*/
.Input {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  margin-bottom: 1.125rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 400; }
  .Input input {
    height: 50px;
    padding: 0 25px;
    border-radius: 5px;
    border: none;
    background-color: #2e418a;
    outline: none;
    box-shadow: none;
    color: #fff;
    max-width: 50%;
    font-size: 0.875rem;
    font-weight: lighter; }
    .Input input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.21);
      font-size: 0.875rem;
      font-weight: lighter; }
    .Input input[type="checkbox"] {
      -webkit-appearance: none;
      width: 35px;
      max-width: 35px;
      height: 35px;
      max-height: 35px;
      border: 1px solid rgba(255, 255, 255, 0.21);
      padding: 0;
      flex-grow: 0;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      background-position: center;
      background-size: 0;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: all 50ms ease-out; }
      .Input input[type="checkbox"]:checked {
        background-size: 90%;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAABZklEQVRoQ+2aMU7DQBBF/5A2RY6w9BhxgyQ3gJtAj8FgergJ3CDODRCmZ4+QghYGLVIUiBzISJFmY32X1mhn/vw33Rf0/JOe6wMF7rvDdLB8CWMRnAAYZebmQhXP9XGc/zXXRgertzD6eMejQCaZCfs1jkKbwRBn1WFcdM25UWDZhlnu4paCksi6iNOtBV62YXIAmeXs3Ppsn9DpXRGb9f+dDpav4VxU7vdJoIpe1EfxYSuBV22oALneJ4GA3twWsaLAtAE6mCW7RHRlCxEloh4b4A3yBj24M/QkokTUgItHKREloh7cGXoSUSJqwMWjlIgSUQ/uDD2JKBE14OJRSkSJqAd3hp5ElIgacPEoJaJE1IM7Q08iulpW70MIvY+RJC/LNjQCGRuOwK1UofO6iJ2JrP+iXE+5i0ziBkOcmqNcSzsSrimMJ5pXGE8F32G8rnTTT5QYp3Q7rB01poM7WqTbM7138Ase9ipI7Sk4dwAAAABJRU5ErkJggg=="); }
  .Input label {
    font-size: 22px;
    font-weight: 300;
    text-align: left; }
    .Input label span {
      text-align: left; }
  .Input.Input--vertical {
    flex-direction: column; }
    .Input.Input--vertical label {
      display: inline-flex;
      flex-grow: 1;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem; }
      .Input.Input--vertical label span {
        width: 100%; }
    .Input.Input--vertical input {
      display: inline-flex;
      flex-grow: 1;
      width: calc(100% - 32px);
      max-width: 100%; }
    .Input.Input--vertical.Input--reverse {
      flex-direction: column-reverse;
      margin-bottom: 0;
      margin-top: 1rem; }
      .Input.Input--vertical.Input--reverse label {
        margin-bottom: 0;
        margin-top: 1rem; }
  .Input.Input--start {
    justify-items: flex-start;
    align-items: flex-start; }
    .Input.Input--start.Input--reverse {
      justify-items: flex-start;
      justify-content: flex-start; }
    .Input.Input--start label {
      text-align: left; }
      .Input.Input--start label span {
        text-align: left; }
    .Input.Input--start input,
    .Input.Input--start button {
      text-align: right; }
  .Input.Input--shrink {
    flex-shrink: 1;
    width: auto; }
  .Input.Input--end {
    align-content: flex-end;
    align-items: center; }
    .Input.Input--end.Input--reverse {
      justify-items: flex-end;
      justify-content: flex-end; }
    .Input.Input--end label {
      text-align: right; }
      .Input.Input--end label span {
        text-align: right; }
    .Input.Input--end input,
    .Input.Input--end button {
      text-align: right; }
  .Input:not(.Input--vertical) label {
    display: inline-flex;
    flex-shrink: 1; }
  .Input:not(.Input--vertical) input {
    display: inline-flex;
    flex-grow: 1; }
  .Input:not(.Input--vertical).Input--reverse {
    flex-direction: row-reverse; }
    .Input:not(.Input--vertical).Input--reverse label {
      display: inline-flex;
      flex-shrink: 1;
      margin-right: 0;
      margin-left: 16px; }
  .Input .Input--small--label {
    padding-left: 15px;
    font-size: 18px;
    opacity: 0.6; }
  .Input a {
    margin: 16px 0; }
  .Input.is-disabled input,
  .Input.is-disabled button,
  .Input.is-disabled div:first-of-type {
    opacity: .8;
    cursor: not-allowed !important;
    pointer-events: none;
    touch-action: none; }
  .Input.is-disabled label {
    opacity: .5;
    cursor: not-allowed; }
  .Input.is-required label::after {
    content: '*';
    color: #d0021b;
    position: relative;
    top: 0;
    margin: 0 5px; }

.AutoCompleteTextInput {
  position: relative;
  bottom: -5.9rem;
  z-index: 9999;
  width: 100%;
  max-width: 80%;
  background-color: transparent;
  margin-bottom: 0; }
  .AutoCompleteTextInput input {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    margin-bottom: .2rem; }
  .AutoCompleteTextInput.is-disabled {
    cursor: not-allowed;
    width: 100%;
    max-width: 100%;
    opacity: 1; }

.Input-label {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  display: inline-flex;
  flex-grow: 1; }
  .Input-label span {
    display: inline-block;
    overflow: hidden; }

.is-dark label {
  color: rgba(0, 0, 0, 0.31); }

.is-dark input {
  background-color: rgba(1, 1, 1, 0.21); }
  .is-dark input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff; }

.SelectInput {
  position: relative;
  text-align: left;
  z-index: 200;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 400; }
  .SelectInput label {
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 22px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 21px;
    display: block; }
  .SelectInput.is-open .SelectInput-button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .SelectInput.is-open .SelectInput-arrow {
    transform: rotate(180deg); }
  .SelectInput.is-disabled .SelectInput-button {
    opacity: 0.5;
    cursor: default; }
  .SelectInput .SelectInput-arrow {
    pointer-events: none;
    transition: all 300ms ease-out; }
  .SelectInput .SelectInput-delete {
    position: absolute;
    right: 60px;
    top: 15px;
    pointer-events: none; }
  .SelectInput .SelectInput-button {
    border: 0;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    background-color: #2e418a;
    border-radius: 3px;
    padding: 15px 28px;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    cursor: pointer;
    height: 50px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    z-index: 9999; }
    .SelectInput .SelectInput-button * {
      pointer-events: none; }
    .SelectInput .SelectInput-button:focus {
      outline: 0;
      box-shadow: none; }
  .SelectInput .SelectInput-list {
    position: absolute;
    top: 90px;
    left: 0;
    box-sizing: border-box;
    background-color: rgba(46, 65, 138, 0.8);
    max-height: 0;
    overflow: hidden;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 250ms ease-out; }
    .SelectInput .SelectInput-list.is-visible {
      max-height: 270px;
      overflow: hidden; }

.AutoCompleteWrapper {
  max-width: 600px;
  margin-top: -6.8rem;
  height: 107px;
  flex-grow: 1; }
  .AutoCompleteWrapper input:disabled {
    width: 100%;
    max-width: 100%; }
