@import url("https://fonts.googleapis.com/css?family=Righteous|Source+Sans+Pro:300,400,700");
@import "./shared/styles/index.scss";

body {
	font-family: $font-base;
	font-weight: 400;
	background-color: $color-violet;
	width: 100%;
}

a {
	color: $color-green;
}

/*
@todo: Move these class to another file scss (if they are necessary)
 */
.AdminSectionTitle {
	@include font-source-sans-pro(22, $color-white, 400, 30);

	& em {
		opacity: 1;
		color: $color-white;
		font-style: initial;
		font-weight: bolder;
	}
}

ul.AdminSectionText {
	padding: 0 0 0 20px;
}

.AdminSectionText {
	@include font-source-sans-pro(18, rgba($color-white, .6), 300, 26);
	@include color-opacity($color-white, 0.6);

	& em {
		opacity: 1;
		color: $color-white;
		font-style: initial;
		font-weight: bolder;
	}

	li {
		margin: 0 0 10px 0;
	}

	>span>ul {
		padding: 0 0 0 20px;
	}

	&.AdminSectionText--small {
		@include font-source-sans-pro(14, rgba($color-white, .6), 300, 26);
	}
}

.katex {
	font-size: 22px !important;
}

.u-fullWidth {
	width: 100% !important;
}

.u-shrinkAll>* {
	flex-grow: 0 !important;
	flex-shrink: 1 !important;
}

.rdw-inline-wrapper {
	.rdw-option-active {
		background-color: #FFF !important;
		border-color: #FFF !important;
	}

	.public-DraftStyleDefault-block {
		margin: 0;
	}
}

/// MS BROWSER FIX
::-ms-check,
::-ms-clear {
	display: none;
}

/// FIREFOX FIXES
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}