@import "../../shared/styles/index.scss";

.Timer {
  display: inline-flex;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  flex-shrink: 1;

  .TimerCounter {
    @include font-source-sans-pro(14, $color-white, 800);
    text-transform: uppercase;
  }
}