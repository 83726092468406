@import "../../shared/styles/index.scss";

.Button {
	@include font-source-sans-pro(22, $color-violet, 700);
	border: 0;
	padding: 20px 25px;
	transition: opacity 250ms;
	cursor: pointer;
	border-radius: 51px;
	background-color: $color-white;
	display: inline-block;
	text-decoration: none;
	min-width: 240px;
	text-align: center;
	margin: 20px 0;
	text-transform: uppercase;
	box-sizing: border-box;
	white-space: nowrap;

	>img {
		margin: 0 10px 0 0;
	}

	&.Button--small {
		@include font-source-sans-pro(14, $color-violet, 700);
		min-width: 140px;
		padding: 7px 16px;
	}

	>span {
		pointer-events: none;
	}

	&.Button--secondary {
		background-color: $color-pink;
		color: $color-white;
	}

	&.Button--tertiary {
		background-color: $color-lightBlue;
		color: $color-white
	}

	&.Button--confirm {
		background-color: $color-violet;
		height: 50px;
		padding: 0;
		@include font-source-sans-pro(15, $color-white, 700);
	}

	&.Button--undo {
		@include background-opacity($color-black, 0.17);
		height: 50px;
		padding: 0;
		@include font-source-sans-pro(15, $color-white, 700);
	}

	&.Button--tab--active {
		display: inline-flex;

		align-content: center;
		justify-content: center;
		align-items: center;

		width: 221px;
		height: 44px;

		border-radius: 22px;

		@include font-righteous(14, $color-darkViolet, 400);

		transition: all 250ms ease-out;

		&:hover {
			opacity: 1;
		}
	}

	&.Button--tab--inactive {
		cursor: pointer;

		opacity: 1;
		background-color: $color-darkViolet;

		display: inline-flex;

		align-content: center;
		justify-content: center;
		align-items: center;

		width: 221px;
		height: 44px;

		border-radius: 22px;

		@include font-righteous(14, rgba($color-white, .5), 400);

		&:hover {
			opacity: 1;
		}

		transition: all 250ms ease-out;
	}

	&.Button--disabled {
		opacity: 0.6;
		cursor: default;

		&:hover {
			opacity: 0.6;
		}
	}

	&:hover {
		opacity: 0.75;
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&.Button--outline {
		background-color: transparent;
		border: 1px solid $color-white;
		color: $color-white;
		transition: all 300ms;

		&:hover {
			opacity: 1;
			background-color: $color-white;
			color: $color-violet;
		}

		&.Button--secondary {
			border: 1px solid $color-pink;
			color: $color-pink;

			&:hover {
				opacity: 1;
				background-color: $color-white;
				color: $color-pink;
			}
		}
	}
}
