@import "../../../shared/styles/index.scss";

.OverHeader {
  background-color: #0046AD;
  height: 100px;
  color: #fff;
  display: flex;
  padding: 0 33px;
  align-items: center;
  justify-content: space-between;
  z-index: 99999;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 22px;

  img {
    margin: auto;
  }

  img.WelcomeLogo {
    height: 18px;
    margin-top: 51px;
  }


  img.AppLogo {
    height: 36px;
    margin: 0;
  }

  img.AreaLogo {
    height: 33px;
    margin: 0;

  }
}


