.Loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  .Loader-first {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loaderRotation 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .Loader-second {
    animation-delay: -0.5s;
  }
}

@keyframes loaderRotation {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
