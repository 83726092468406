@import "../../../../shared/styles/index.scss";

.SelectItem {
  @include font-source-sans-pro(14, $color-white, 400);
  display: block;
  text-decoration: none;
  transition: color 250ms;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 15px 28px;
  margin: 0 auto;
  width: 100%;
  text-align: left;
  z-index: 200;

  span {
    pointer-events: none;
  }

  &:hover {
    @include background-opacity($color-black, 0.2);
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}